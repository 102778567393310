import React, { useState, useEffect, useRef } from "react";
import "animate.css";
import { CgWebsite } from "react-icons/cg";
import { TbReportAnalytics } from "react-icons/tb";
import { BiCustomize } from "react-icons/bi";
import { GrSystem } from "react-icons/gr";

const icons = [CgWebsite, TbReportAnalytics, BiCustomize, GrSystem];

const bgColors = ["bg-red-500", "bg-blue-500", "bg-green-500", "bg-yellow-500"];

function Process({ data }) {
  const processItems = data?.ProcessSection?.card || [];
  const divRefs = useRef(processItems.map(() => React.createRef()));
  const [divInView, setDivInView] = useState(processItems.map(() => false));

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;

      divRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect();
          if (top < screenHeight && bottom >= 0) {
            setTimeout(() => {
              setDivInView((prevInView) => {
                const updatedInView = [...prevInView];
                updatedInView[index] = true;
                return updatedInView;
              });
            }, index * 200);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-full bg-[#f4f4f4] pb-24 sm:pb-12">
      <div
        className={`${
          divInView.some((inView) => inView)
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        } flex justify-center text-center pt-4`}
      >
        <p className="titlecontent">
          {data && data.ProcessSection && data.ProcessSection.title}
          <span className="text-warning">.</span>
        </p>
      </div>
      <div className="w-full px-16 sm:px-4 pt-2 grid grid-cols-4 md:grid-cols-2 sm:grid-cols-2 justify-center gap-4">
        {processItems.map((item, index) => {
          const IconComponent = icons[index % icons.length];
          const bgColor = bgColors[index % bgColors.length];
          return (
            <div
              key={index}
              ref={divRefs.current[index]}
              className={`flex flex-col bg-white gap-2 justify-start text-center items-center p-4 rounded-3xl w-fit hover:shadow-[0_0_60px_rgba(0,0,0,0.1)] ${
                divInView[index]
                  ? "animate__animated animate__fadeInUp animate__slow"
                  : "opacity-0 translate-y-10"
              } transition-transform duration-500`}
            >
              <div className="flex justify-start items-start">
                <div
                  className={`relative flex justify-center items-center rounded-full ${bgColor} h-[80px] w-[80px]`}
                  alt={`Process step ${index + 1}`}
                >
                  <IconComponent className="text-white" size={36} />
                </div>
              </div>
              <div className="text-gray-700 font-normal text-[16px] p-0">
                {item.des}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Process;
