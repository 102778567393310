import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";

function Ourclients({ data }) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <div className="w-full py-16 sm:py-8">
      <div
        ref={inViewRef}
        className={`flex sm:px-4 flex-col items-center justify-center ${
          inView
            ? "animate__animated animate__fadeInLeft animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <span className="titlecontent">
          {data && data.ClientsSection && data.ClientsSection.title}
          <span className="text-warning">.</span>
        </span>
      </div>
      <div
        ref={inViewRef}
        className={`flex flex-col items-center justify-center ${
          inView
            ? "animate__animated animate__fadeInLeft animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <div className="subcontenttitle px-4 text-center">
        {data && data.ClientsSection && data.ClientsSection.subcontent}
        </div>
      </div>
      <div
        className={`flex w-full justify-center items-center sm:px-4 pt-3 ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <img
          src="/logo-client.png"
          className="w-[1000px] sm:w-full"
          alt="logo-client"
        ></img>
      </div>
    </div>
  );
}

export default Ourclients;
