import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { Segmented, Tabs } from "antd";

function Navbar({ data, changeLanguage }) {
  const [activeLink, setActiveLink] = useState("home");
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false);
  const [language, setLanguage] = useState("TH");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById("home");
      const aboutSection = document.getElementById("about");
      const servicesSection = document.getElementById("services");
      const processSection = document.getElementById("process");
      const productsSection = document.getElementById("products");
      const clientsSection = document.getElementById("clients");
      // const blogSection = document.getElementById("blog");
      const contactSection = document.getElementById("contact");

      if (window.scrollY < homeSection.offsetTop) {
        setActiveLink("home");
        // } else if (window.scrollY < aboutSection.offsetTop) {
        //   setActiveLink("about");
      } else if (window.scrollY < servicesSection.offsetTop) {
        setActiveLink("services");
      } else if (window.scrollY < processSection.offsetTop) {
        setActiveLink("process");
      } else if (window.scrollY < productsSection.offsetTop) {
        setActiveLink("products");
      } else if (window.scrollY < clientsSection.offsetTop) {
        setActiveLink("clients");
        // } else if (window.scrollY < blogSection.offsetTop) {
        //   setActiveLink("blog");
      } else if (window.scrollY < contactSection.offsetTop) {
        setActiveLink("contact");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    changeLanguage(newLanguage);
  };

  const options = [
    {
      label: (
        <div className="d-flex justify-content-center">
          <img
            src="/TH-Flag.svg"
            alt="TH"
            loading="lazy"
            style={{
              width: "20px",
              marginTop: "35%",
              height: "auto",
            }}
          />
        </div>
      ),
      value: "TH",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <img
            src="/EN-Flag.svg"
            alt="EN"
            loading="lazy"
            style={{
              width: "20px",
              marginTop: "35%",
              height: "auto",
            }}
          />
        </div>
      ),
      value: "EN",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <img
            src="/CN-Flag.svg"
            alt="CN"
            loading="lazy"
            style={{
              width: "20px",
              marginTop: "35%",
              height: "auto",
            }}
          />
        </div>
      ),
      value: "CN",
    },
  ];

  return (
    <>
      <div className="w-full navmain sticky">
        <nav className="navbar">
          <div className="navbar-logo">
            <a href="">
              <img
                src="/Perfectcom LOGOV1 (2).png"
                alt="Logo"
                className="logo-img"
              />
            </a>
          </div>
          <Segmented
            value={language}
            className="bg-slate-200 sm:flex hidden"
            onChange={handleLanguageChange}
            options={options}
          />
          <div
            className={`hamburger mr-4 ${menuOpen ? "active" : ""}`}
            onClick={toggleMenu}
          >
            <div className="hamburger-bar"></div>
            <div className="hamburger-bar"></div>
            <div className="hamburger-bar"></div>
          </div>
          <ul
            className={`flex gap-3 items-center p-0 m-0 nav-list ${
              menuOpen ? "active" : ""
            }`}
          >
            <li
              className={
                activeLink === "home"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                  {data.NavbarElement.home}
                </span>
              </Link>
            </li>
            <li
              className={
                activeLink === "services"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                  {data.NavbarElement.services}
                </span>
              </Link>
            </li>
            <li
              className={
                activeLink === "process"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="process"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                  {data.NavbarElement.process}
                </span>
              </Link>
            </li>
            <li
              className={
                activeLink === "clients"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="clients"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                  {data.NavbarElement.clients}
                </span>
              </Link>
            </li>
            <li
              className={
                activeLink === "products"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="products"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                   {data.NavbarElement.products}
                </span>
              </Link>
            </li>
            <li
              className={
                activeLink === "contact"
                  ? "nav-item active p-0 m-0"
                  : "nav-item p-0 m-0"
              }
            >
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-110}
                duration={500}
                onClick={toggleMenu}
              >
                <span className="nav-link p-0 m-0">
                  {data.NavbarElement.contace}
                </span>
              </Link>
            </li>
            <div className="flex pr-4 sm:pr-0 sm:w-full sm:flex-col sm:pb-8 items-center gap-2">
              <Segmented
                value={language}
                className="bg-slate-200 sm:hidden"
                onChange={handleLanguageChange}
                options={options}
              />
              <a
                href="https://lin.ee/KhwIenD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn rounded-[20px] text-white !bg-emerald-600 hover:!bg-emerald-700 hover:border-[#047857] border border-[#059669] active rounded-pill px-3 m-0"
                  data-mdb-ripple-color="dark"
                >
                  ปรึกษาฟรี
                </button>
              </a>
              <a
                href="https://lin.ee/KhwIenD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn btn-outline-danger active rounded-pill px-3 m-0"
                  data-mdb-ripple-color="dark"
                >
                  ขอใบเสนอราคา
                </button>
              </a>
            </div>
          </ul>
        </nav>
      </div>
      <div className="flex justify-end">
        <motion.div
          className={`flex items-center w-fit py-2 px-12 ${
            loginmodal ? "open" : ""
          }`}
          style={{
            border: "1px solid #EEF6FF",
            backgroundColor: "#fff",
            position: "fixed",
            color: "#000",
            right: 140,
            top: 70,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "4px 4px 4px 0px rgba(160, 210, 255, 0.10)",
            cursor: "pointer",
            zIndex: 4,
          }}
          initial={false}
          animate={{
            y: loginmodal ? 0 : "-200%",
            opacity: loginmodal ? 1 : 0,
          }}
          exit={{
            opacity: 0,
            y: "-200%",
          }}
          transition={{ duration: 0.4 }}
        >
          <div className="flex flex-col whitespace-nowrap">
            <a
              href="https://hrm.perfectcomsolutions.com/login.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mt-2 border-none">
                <p>HRM</p>
              </div>
            </a>
            <a
              href="https://saleforce.perfectcomsolutions.com/page_login.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mt-2 border-none">
                <p>Sale Force</p>
              </div>
            </a>
            <a
              href="https://istock.perfectcomsolutions.com/dist/login.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mt-2 border-none">
                <p>Istock</p>
              </div>
            </a>
            <a
              href="https://elearning.perfectcomsolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mt-2 border-none">
                <p>E-Learning</p>
              </div>
            </a>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default Navbar;
