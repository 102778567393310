import React from 'react';
import 'animate.css';
import { IoChatbubbleEllipses } from 'react-icons/io5';

function Subhead({ data }) {
  return (
    <div className="relative flex justify-center items-center w-full h-fit overflow-hidden">
      <img
        src="/bg-banner.png"
        alt="bg-cover"
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      {/* Start Message */}
      <div className="relative flex sm:flex-col md:flex-col h-full w-full justify-between z-10">
        <div className="w-full top-0 flex px-12 sm:px-4 pb-20 sm:pt-8 sm:pb-8 items-center">
          <div className="flex flex-col md:pt-8 justify-start sm:justify-center sm:items-center sm:text-center md:text-center w-full">
            <h1 className="text-[64px] lg:text-[48px] md:text-[40px] cover-title sm:text-[32px] bg-gradient-to-r from-[#C32929] via-[#FE0101] to-[#FD6537] inline-block text-transparent bg-clip-text animate__animated animate__fadeInDown animate__slow">
              PERFECT COMPUTER
            </h1>
            <h2 className="text-[64px] lg:text-[48px] md:text-[40px] cover-title sm:text-[32px] bg-gradient-to-r from-[#C32929] via-[#FE0101] to-[#FD6537] inline-block text-transparent bg-clip-text animate__animated animate__fadeInUp animate__slow">
              SOLUTIONS
            </h2>
            <div className="flex flex-col md:items-center gap-6 sm:gap-2 sm:items-center animate__animated animate__fadeInUp animate__slow pt-2">
              <p className="text-[18px] sm:text-[14px] font-normal text-[#6C6C6C] p-0 m-0">
                {data && data.HeaderSection && data.HeaderSection.description}
              </p>
              <p className="text-[40px] sm:text-[18px] md:text-[26px] p-0 m-0 text-[#FE0101]">
                {data && data.HeaderSection && data.HeaderSection.slogan}
              </p>
              <p className="text-[26px] md:text-[20px] sm:text-[16px] font-medium text-black p-0 m-0">
              {data && data.HeaderSection && data.HeaderSection.callAction}
              </p>
              <a
                href="https://lin.ee/KhwIenD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="flex w-fit gap-2 py-2 px-3 items-center rounded-[20px] !bg-[#11C42F] hover:!bg-[#0BB127] rounded-pill"
                >
                  <IoChatbubbleEllipses size={20} color="#ffff" />
                  <p className="text-[24px] p-0 m-0 sm:text-[14px] text-white">
                  {data && data.HeaderSection && data.HeaderSection.button}
                  </p>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex w-full pt-20 md:pt-0 sm:pt-0 sm:px-8 items-end justify-end animate__animated animate__fadeInRight animate__slow">
          <img
            src="/phone.png"
            className="w-full h-auto max-h-[800px] sm:max-h-[260px] md:max-h-[380px] object-contain"
            alt="aw-section"
          />
        </div>
      </div>
      {/* End Message */}
    </div>
  );
}

export default Subhead;
