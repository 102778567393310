import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";

function Product({ data }) {
  const [activeContent, setActiveContent] = useState(2);
  const scrollRef = useRef(null);
  const serviceItems = data?.ProductSection?.button || [];
  const divRefs = useRef(serviceItems.map(() => React.createRef()));

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const slider = scrollRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    });
  }, []);

  const scrollToSection = (targetId) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const isButtonActive = (id) => {
    return activeContent === id
      ? "bg-[#e55951] text-white rounded-full px-2 py-1"
      : "text-[#e55951] bg-white px-2 py-1";
  };

  return (
    <div className="w-full bg-[#f4f4f4] py-16 sm:py-8">
      <div
        ref={inViewRef}
        className={`flex sm:px-4 flex-col items-center justify-center ${
          inView
            ? "animate__animated animate__fadeInRight animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <p className="titlecontent pt-3">
          {data && data.ProductSection && data.ProductSection.title}
          <span className="text-warning">.</span>
        </p>
      </div>
      <div
        className={`flex justify-center pb-3 text-center ${
          inView
            ? "animate__animated animate__fadeInRight animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <div className="subcontenttitle px-4">
          {data && data.ProductSection && data.ProductSection.subcontent}
        </div>
      </div>
      <div
        className={`overflow-x-auto sm:overflow-x-scroll sm:scrollbar-hide ${
          inView
            ? "animate__animated animate__fadeInRight animate__slow"
            : "opacity-0 translate-y-10"
        }`}
        ref={scrollRef}
      >
        <div className="flex sm:px-8 gap-2 items-center justify-center text-center pb-4 whitespace-nowrap sm:w-max">
          {data &&
            data.ProductSection &&
            data.ProductSection.button.map((button) => (
              <button
                key={button.id}
                type="button"
                className={`px-2 py-1 rounded-full border border-[#e55951] ${isButtonActive(
                  button.id
                )}`}
                data-mdb-ripple-color="dark"
                onClick={() => {
                  setActiveContent(button.id);
                  scrollToSection(button.id);
                }}
              >
                <p className={`w-fit md:text-sm p-0 m-0 ${isButtonActive(button.id)}`}>
                  {button.title}
                </p>
              </button>
            ))}
        </div>
      </div>
      <div
        className={`flex flex-wrap md:grid md:grid-cols-2 justify-center px-24 sm:px-8 md:px-8 gap-4 ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        {data &&
          data.ProductSection &&
          data.ProductSection.button.map((button, index) => (
            <React.Fragment key={index}>
              {(button.id === 1 || button.id === 7) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-9.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          Avenger Criminal Checker
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 7) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-20.png"
                        alt="ระบบบริหารงานจราจรขับดี"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบบริหารงานจราจรขับดี
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 7) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-17.png"
                        alt="ระบบบริหารงานจราจรขับดี"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบ OCR บัตรประชาชน
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 7) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-18.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบ OCR เปรียบเทียบใบหน้า
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 7) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-17.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบ OCR ใบขับขี่
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 4) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-10.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">PFCI Incident</h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">ERP System</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 4) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-1.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">Echo Games</h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">ERP System</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 4) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-16.2.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบบริหารจัดการงานผลิตผลไม้อบแห้ง
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">ERP System</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 6) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-14.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบบริหารจัดการห้องประชุม
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">
                            Customize Software
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 6) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-12.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ระบบตรวจสอบบัญชีคนโกงก่อนโอน(เช็กก่อน)
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">
                            Customize Software
                          </span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 6) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-4.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          งานบริหารสืบสวน
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">
                            Customize Software
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 5) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-5.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ร่มโพธิ์ทอง 888
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">Design</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 5) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-15.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                          ร่มโพธิ์ทอง 888 โปรดักส์ กรุ๊ป จำกัด
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">Design</span>
                          <span className="tag text-white">CMS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 2) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-6.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                        Raipoong Thailand
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">E-Learning</span>
                          <span className="tag text-white">Website</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 2) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-21.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                        วปอ.บอ. (NDCFL)
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">E-Learning</span>
                          <span className="tag text-white">Website</span>
                          <span className="tag text-white">AI</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 2) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-8.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">
                        Feni Academy
                        </h4>
                        <div className="tags">
                          <span className="tag text-white">E-Learning</span>
                          <span className="tag text-white">Website</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 3) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-7.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">NDSI Thailand</h4>
                        <div className="tags">
                          <span className="tag text-white">E-Learning</span>
                          <span className="tag text-white">LMS</span>
                          <span className="tag text-white">Website</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(button.id === 1 || button.id === 8) && (
                <div
                  className={`sm:w-full w-1/4 md:w-full ${
                    activeContent === button.id ? "" : "d-none"
                  }`}
                  id={button.id}
                >
                  <div className="card-product">
                    <div className="content">
                      <img
                        className="img"
                        src="/product/productbanner-11.png"
                        alt="productbanner"
                      />
                      <div className="card-footer bottom-0 flex flex-col justify-end items-start">
                        <h4 className="text-white font-bold">ชุมชนยั่งยืน</h4>
                        <div className="tags">
                          <span className="tag text-white">Power BI</span>
                          <span className="tag text-white">Website</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

export default Product;
